<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card color="primary" dark>
        <v-card-title class="headline">Espere por favor</v-card-title>
        <v-card-text>
          {{ texto }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    texto: {
      type: String,
      default: 'Estamos Procesando tu solicitud'
    },
  }
};
</script>