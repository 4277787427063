import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import utils from '@/plugins/Utils.js';
import moment from 'moment';
import QRCode from 'qrcode'

const reporteFactura = {}
//A4 595 x 842
const cm2px = 28;                       // cantidad de pixeles en un centimetro (pantalla)
const lm = 20;                          // margen izquierdo contenedor
const rm = 20;                          // margen izquierdo contenedor
const tm = 20;                          // margen superior contenedor
const bm = 20;                          // margen inferior contenedor
const wp = 595;                         // ancho pagina
const hp = 842;                         // alto pagina
const hfoot = hp - bm - (3 * cm2px);    // alto inicio del pie
const wc = wp - lm - rm;                // ancho contenedor
const itemsxpag = 20;                   // limita la cantidad de items de la tabla
const limite = hfoot - (3.5 * cm2px) - (0.5 * cm2px);
// const hc = hp-tp-bm;                 // alto contenedor
const centro = 298;                     // centro de la hoja
const fontName = 'Helvetica';
let pag_act = 1;
let pag_total = 99;
let calculo_hcc = 0;

//diccionario de codigos de afip
const dictCodAFIP = { 1: 'Factura', 6: 'Factura', 11: 'Factura', 19: 'Factura', 51: 'Factura', 2: 'Nota de Débito', 7: 'Nota de Débito', 12: 'Nota de Débito', 20: 'Nota de Débito', 52: 'Nota de Débito', 3: 'Nota de Crédito', 8: 'Nota de Crédito', 13: 'Nota de Crédito', 21: 'Nota de Crédito', 53: 'Nota de Crédito', 201: 'Factura de Crédito electrónica MiPyMEs (FCE)', 206: 'Factura de Crédito electrónica MiPyMEs (FCE)', 211: 'Factura de Crédito electrónica MiPyMEs (FCE)', 202: 'Nota de Débito electrónica MiPyMEs (FCE)', 207: 'Nota de Débito electrónica MiPyMEs (FCE)', 212: 'Nota de Débito electrónica MiPyMEs (FCE)', 203: 'Nota de Crédito electrónica MiPyMEs (FCE)', 208: 'Nota de Crédito electrónica MiPyMEs (FCE)', 213: 'Nota de Crédito electrónica MiPyMEs (FCE)', 4: 'Recibo', 9: 'Recibo', 15: 'Recibo', 54: 'Recibo', 10: 'Nota de Venta al contado', 5: 'Nota de Venta al contado', 60: 'Cuenta de Venta y Líquido producto', 61: 'Cuenta de Venta y Líquido producto', 63: 'Liquidación', 64: 'Liquidación', 91: 'Remito', 39: '???? (R.G. N° 3419)', 40: '???? (R.G. N° 3419)' };


reporteFactura.generarPDF = async function (data) {
    pag_act = 1;
    //console.log(data);

    var doc = new jsPDF(
        {
            orientation: 'p',
            unit: 'pt',
            format: 'a4',
            putOnlyUsedFonts: true,
            floatPrecision: 2 // or "smart", default is 16
        }
    );
    // console.log(doc.getFontList());
    doc.setFont(fontName)
    doc.setFontSize(10);

    //calculo de cantidad de paginas para items fv1
    let cant_items = data.fv1.length;
    let cant_pages = Math.ceil(cant_items / itemsxpag);
    let cant_items_up = (cant_items % itemsxpag);

    //calculo de caracteristicas de cabecera
    let items = data.carcab;
    let cant_cc = items.length;
    let alto_cuadro_cc = (cant_cc + 1) * 12;
    // doc.line(lm,((cant_items_up + 1) * 32), (wp-rm), ((cant_items_up + 1) * 32));
    calculo_hcc = ((cant_items_up + 1) * 33) + alto_cuadro_cc;
    if(limite <= calculo_hcc){
        pag_total = cant_pages + 1;
    }else{
        pag_total = cant_pages;
    }

    //armado de QR
    let stringB64 = btoa(data);
    let baseURL = 'https://www.afip.gob.ar/fe/qr/?p='

    let URLQR = baseURL + stringB64;
    let QR = null;

    await QRCode.toDataURL(URLQR)
        .then(response_QR => {
            QR = response_QR;
        })
        .catch(err => {
            console.error(err)
        });


    for (let i = 0; i < cant_pages; i++) {
        var h = tm;

        h = cabecera(doc, h, data);

        //tabla de items
        doc.saveGraphicsState();
        h = grillaItems(doc, h, data, (i + 1));
        doc.restoreGraphicsState();

        //pie de pagina
        let hpie = hfoot;
        hpie = pieAFIP(doc, hpie, data, QR);

        if ((i + 1) < cant_pages) {
            doc.addPage();
            pag_act++;
            doc.setDrawColor('#000000');
        }
    }


    //cuadro de totales y observaciones
    cierreFactura(doc, h, data, QR);
    // h = hp - bm - (6.7 * cm2px);
    // h = cuadroTotales(doc, h, data);


    window.open(URL.createObjectURL(doc.output("blob")));
    //doc.save('Factura-'+codigo+'.pdf');
};

const cabecera = function(documento, h, data){
    h = tm;  //reinicializar el puntero
    h = cuadroIdCopia(documento, h);
    h = cuadroCabecera(documento, h, data);
    // h = cuadroPeriodos(doc, h);
    h = cuadroDatosCliente(documento, h, data);

    return h
}

//Cuadro id copia
const cuadroIdCopia = function (documento, h) {
    var altoCuadro = 1 * cm2px;

    //rectangulo
    documento.rect(lm, h, wc, altoCuadro);
    //etiqueta
    documento.setFontSize(14);
    documento.setFont(fontName, 'Bold');
    documento.text('ORIGINAL', centro, 40, {
        align: 'center'
    });

    h += altoCuadro;  //actualizo h
    return h;
};

//cuadro cabecera
const cuadroCabecera = function (documento, h, data) {
    var altoCuadro = 4 * cm2px;
    //rectangulo
    documento.rect(lm, h, wc, altoCuadro);
    //cuadro letra
    //borde
    documento.rect((centro - (0.8 * cm2px)), h, (1.6 * cm2px), (1.3 * cm2px));

    //letra
    documento.setFontSize(20);
    documento.setFont(fontName, 'Bold');
    documento.text(data.fv0.fv0_tipfor, centro, (h + 0.7 * cm2px), { align: 'center' });

    //codigo tip cmp afip
    documento.setFontSize(8);
    documento.setFont(fontName, 'Bold');
    documento.text('COD. 0' + data.fv0.nus_codafipcmp, centro, (h + 1.2 * cm2px), { align: 'center' });


    //linea divisoria
    documento.line(centro, (h + 1 + (1.3 * cm2px)), centro, (h + altoCuadro));

    //cuadro izquierdo
    let haux = h + 1 * cm2px;
    let laux = lm + 10;

    //logo o Razsoc grande
    //falta el if para ver si tiene logo
    documento.setFontSize(18);
    documento.setFont(fontName, 'Bold');
    let splitTitle = documento.splitTextToSize(data.emp.emp_razsoc, (8.5 * cm2px));  //esto hace que se quiebre la frase
    documento.text(splitTitle, (laux + ((8.5 / 2) * cm2px)), haux, { align: 'center' });

    haux += 1.5 * cm2px;


    //razon social
    documento.setFontSize(9);
    documento.setFont(fontName, 'Bold');
    documento.text('Razón Social: ' + data.emp.emp_razsoc, laux, haux);
    haux += 18;

    //domicilio
    documento.setFontSize(9);
    documento.setFont(fontName, 'Bold');
    documento.text('Domicilio Comercial: ' + data.emp.emp_direcc, laux, haux);
    haux += 18;

    //condicion IVA
    documento.setFontSize(9);
    documento.setFont(fontName, 'Bold');
    documento.text('Condición frente al IVA: ' + data.emp.tiv_descri, laux, haux);

    //cuadro derecho
    haux = h + 1 * cm2px;
    laux = centro + 40;

    //hardcode... traer de hmt
    documento.setFontSize(18);
    documento.setFont(fontName, 'Bold');
    documento.text(dictCodAFIP[data.fv0.nus_codafipcmp].toUpperCase(), laux, haux);
    haux += 18;

    //linea 2 punto de venta y nro comprobante
    documento.setFontSize(9);
    documento.setFont(fontName, 'Bold');
    documento.text('Punto de venta: ' + utils.cerosFill(data.fv0.fv0_sucurs, 5), laux, haux);
    documento.text('Comp Nro: ' + utils.cerosFill(data.fv0.fv0_numero, 8), (laux + 4.3 * cm2px), haux);
    haux += 14;

    //linea 3 fecha emision
    documento.setFontSize(9);
    documento.setFont(fontName, 'Bold');
    documento.text('Fecha de Emisión: ' + moment(data.fv0.fv0_fecemi).format('DD/MM/YYYY'), laux, haux);
    haux += 20;

    //linea 4 CUIT
    documento.setFontSize(9);
    documento.setFont(fontName, 'Bold');
    documento.text('CUIT: ' + data.emp.emp_nrocui, laux, haux);
    haux += 12;

    //linea 5 IIBB
    documento.setFontSize(9);
    documento.setFont(fontName, 'Bold');
    documento.text('Ingresos Brutos: ' + data.emp.emp_nroiib, laux, haux);
    haux += 12;

    //linea 6 F inicio act
    documento.setFontSize(9);
    documento.setFont(fontName, 'Bold');
    documento.text('Fecha de Inicio de Actividades: ' + moment(data.emp.emp_fecini).format('DD/MM/YYYY'), laux, haux);
    haux += 12;

    //fin cuadro derecho

    h += altoCuadro;  //actualizo h
    return h;
}

//cuadro periodos
// eslint-disable-next-line no-unused-vars
const cuadroPeriodos = function (documento, h) {
    //esta corrido unos pixeles
    h += 1;

    var altoCuadro = 0.7 * cm2px;
    //rectangulo
    documento.rect(lm, h, wc, altoCuadro);

    h += altoCuadro;  //actualizo h
    return h;
}


//cuadro datos cliente
const cuadroDatosCliente = function (documento, h, data) {
    //esta corrido unos pixeles
    h += 2;

    var altoCuadro = 2.1 * cm2px;
    //rectangulo
    documento.rect(lm, h, wc, altoCuadro);
    let haux = h + 10;
    let laux = lm + 10;
    let ww = 0;
    let splitTitle = "";

    //CUIT
    documento.setFontSize(8);
    documento.setFont(fontName, 'Bold');
    documento.text('CUIT:', laux, haux);
    ww = documento.getTextWidth('CUIT:');
    documento.setFont(fontName, 'normal');
    documento.text(data.cli.cli_cuit, (laux + ww + 2), haux);

    //razon social
    documento.setFontSize(8);
    documento.setFont(fontName, 'Bold');
    documento.text('Apellido y Nombre / Razón Social:', (laux + (7 * cm2px)), haux);
    ww = documento.getTextWidth('Apellido y Nombre / Razón Social:');
    documento.setFont(fontName, 'normal');
    splitTitle = documento.splitTextToSize(data.cli.cli_razsoc, (7.5 * cm2px));  //esto hace que se quiebre la frase
    documento.text(splitTitle, (laux + (7 * cm2px) + ww + 2), haux);
    haux += 22;

    //Condicion IVA
    documento.setFontSize(8);
    documento.setFont(fontName, 'Bold');
    documento.text('Condición frente al IVA:', laux, haux);
    ww = documento.getTextWidth('Condición frente al IVA:');
    documento.setFont(fontName, 'normal');
    documento.text(data.cli.tiv_descri, (laux + ww + 2), haux);

    //Domicilio
    documento.setFontSize(8);
    documento.setFont(fontName, 'Bold');
    documento.text('Domicilio:', (centro + 20), haux);
    ww = documento.getTextWidth('Domicilio:');
    documento.setFont(fontName, 'normal');
    splitTitle = documento.splitTextToSize(data.cli.cli_direcc, (7.5 * cm2px));  //esto hace que se quiebre la frase
    documento.text(splitTitle, (centro + 20 + ww + 2), haux);
    haux += 22;

    //Condicion venta
    documento.setFontSize(8);
    documento.setFont(fontName, 'Bold');
    documento.text('Condición de venta:', laux, haux);
    ww = documento.getTextWidth('Condición de venta:');
    documento.setFont(fontName, 'normal');
    documento.text(data.fv0.tcv_descri, (laux + ww + 2), haux);

    h += altoCuadro;  //actualizo h
    return h;
}


const grillaItems = function (documento, h, data, numpag) {
    var items = data.fv1;
    var tablaItems = [];
    let cont = 0;
    items.forEach(element => {
        if ((cont >= (itemsxpag * (numpag - 1))) && (cont < (itemsxpag * numpag))) {
            tablaItems.push(
                {
                    fv1_codart: element.fv1_codart,
                    fv1_descri: element.fv1_descri,
                    fv1_cantid: element.fv1_cantid,
                    impuni: ((data.fv0.fv0_tipfor == 'A') ? element.c_fv1_impuniA.toFixed(2) : element.c_fv1_impuniB.toFixed(2)),
                    fv1_descue: element.fv1_descue.toFixed(2),
                    subtotal: ((data.fv0.fv0_tipfor == 'A') ? element.c_fv1_impitemA.toFixed(2) : element.c_fv1_imptotalB.toFixed(2))
                },
            )
        }
        cont++;
    });
    //https://www.npmjs.com/package/jspdf-autotable
    let lasthook = null;
    documento.autoTable({
        columns: [
            { header: "#Art.", dataKey: "fv1_codart" },
            { header: "Producto/Servicio", dataKey: "fv1_descri" },
            { header: "Cantidad", dataKey: "fv1_cantid" },
            { header: "Precio Unit.", dataKey: "impuni" },
            { header: "Imp. Bonif.", dataKey: "fv1_descue" },
            { header: "Subtotal", dataKey: "subtotal" }
        ],
        body: tablaItems,
        margin: { top: (h + 5), left: lm, right: rm },
        styles: {
            fontSize: 8
        },
        columnStyles: {
            fv1_cantid: { halign: 'center' },
            impuni: { halign: 'center' },
            fv1_descue: { halign: 'center' },
            subtotal: { halign: 'center' }
        },
        theme: 'plain',
        didParseCell: function (HookData) {
            lasthook = HookData
        }
    });

    // console.log('lasthook:');
    // console.log(lasthook);
    // console.log(lasthook.table);
    // console.log(lasthook.table.finalY);
    return lasthook.table.finalY;
}

const cierreFactura = function(documento, h, data, qr){
   //aca vamos a tener que calcular los altos y ver si entra en la misma hoja o agregar otra

    h = cuadroCaracteristicasCabecera(documento, h, data, qr);

    h = hp - bm - (7.2 * cm2px);
    h = cuadroTotales(documento, h, data);
}

// eslint-disable-next-line no-unused-vars
const cuadroCaracteristicasCabecera = function (documento, h, data, qr) {
    let haux = h + (0.5 * cm2px);
    let xstart = (lm+5);
    let items = data.carcab;
    // let cant_cc = items.length;
    // let alto_cuadro_cc = (cant_cc + 1) * 12;
    // let calculo = haux + alto_cuadro_cc;
    // let limite = hfoot - (3.5 * cm2px) - (0.5 * cm2px);
    
    // documento.line(lm, calculo_hcc, wc, calculo_hcc);
    // documento.line(lm, limite, wc, calculo_hcc);

    if(limite <= calculo_hcc){
        documento.addPage();
        pag_act++;
        documento.setDrawColor('#000000');

        h = cabecera(documento, h, data);
        haux = h + (0.5 * cm2px);

        //pie de pagina
        // eslint-disable-next-line no-unused-vars
        let hpie = hfoot;
        hpie = pieAFIP(documento, hpie, data, qr);
    }
    
    if(items.length > 0){
        documento.setFontSize(8);
        documento.setFont(fontName, 'normal');
        documento.text("Observaciones:", xstart, haux);
        documento.line(xstart, (haux + 1), xstart + documento.getTextWidth("Observaciones:"), (haux + 1));
        haux += 12;
    }

    items.forEach(element => {
        documento.setFontSize(8);
        documento.setFont(fontName, 'Bold');
        documento.text(element.cca_descri + ': ', xstart, haux);
        let ww = documento.getTextWidth(element.cca_descri + ': ');
        documento.setFont(fontName, 'normal');
        documento.text(element.fvc_valor, (xstart + ww + 2), haux);

        haux += 12;
    });

    return haux;
}
const cuadroObservacionesFV0 = function (documento, h, data) {
    let haux = h + (0.5 * cm2px);

    //obsercvaciones si las tuviese
    if (data.fv0.fv0_observ.trim() !== '') {
        documento.setFontSize(8);
        documento.setFont(fontName, 'BoldOblique');
        documento.text('Observaciones:', (lm + 5), haux);
        haux += (0.3 * cm2px);
        documento.setFont(fontName, 'normal');
        let splitTitle = documento.splitTextToSize(data.fv0.fv0_observ, (13 * cm2px));  //esto hace que se quiebre la frase
        documento.text(splitTitle, (lm + (0.5 * cm2px)), haux);
    }
}

const cuadroTotales = function (documento, h, data) {
    var altoCuadro = 3.5 * cm2px;

    //rectangulo
    documento.rect(lm, h, wc, altoCuadro, 'S');

    let haux = h + (0.3 * cm2px);
    let hrow = 9;
    let wcol1 = (5 * cm2px);
    let wcol2 = (2 * cm2px);
    let xstart = (lm+5);
    
    let tributos = data.tributos;
    if(tributos.length > 0 ){
        documento.setFontSize(8);
        documento.setFont(fontName, 'BoldOblique');
        documento.text("Otros Tributos", xstart, haux);
        documento.setFontSize(6);

        documento.setFont(fontName, 'normal');
        tributos.forEach(element => {
            documento.cell(xstart, haux, wcol1, hrow, element.descripcion);
            documento.cell((xstart+wcol1), haux, wcol2 , hrow, element.importe.toFixed(2),element.idx, 'center');
            haux += hrow;
        });
    }

    cuadroObservacionesFV0(documento, haux, data);
    
    //reset haux
    haux = h + (0.5 * cm2px);

    let items = data.totales;
    items.forEach(function (element, idx, array) {

        if (idx === array.length - 1) {
            documento.setFontSize(12);
            haux += 5;
        } else {
            documento.setFontSize(8);
        }
        documento.setFont(fontName, 'Bold');
        documento.text(element.descripcion, (wp - rm - 83), haux, { align: 'right' });
        documento.setFont(fontName, 'normal');
        documento.text(element.signo, (wp - rm - 80), haux);
        documento.setFont(fontName, 'normal');
        documento.text(element.importe.toFixed(2), (wp - rm - 5), haux, { align: 'right' });

        haux += 12;
    });

    h += altoCuadro;  //actualizo h
    return h;
}

const pieAFIP = function (documento, h, data, qr) {
    let h_sup = h - (0.5 * cm2px);

    documento.line(lm, h_sup, (wp - rm), h_sup);

    //QR
    let formatoLogo = 'PNG';
    let x = (lm + (0 * cm2px));
    let y = (h - (0.4 * cm2px));
    let ancho = (3 * cm2px);
    let alto = (3 * cm2px);

    documento.addImage(qr, formatoLogo, x, y, ancho, alto);
        
    //logo afip
    let logoAfip = new Image();
    logoAfip.src = '/static/afip.png';

    formatoLogo = 'PNG';
    x = (lm + (3.5 * cm2px));
    y = (h - (0.1 * cm2px));
    ancho = (3 * cm2px);
    alto = (1 * cm2px);

    documento.addImage(logoAfip, formatoLogo, x, y, ancho, alto);

    //etiquetas bajo el logo
    y += alto + (0.5 * cm2px);
    documento.setFontSize(9);
    documento.setFont(fontName, 'BoldOblique');
    documento.text('Comprobante Autorizado', x, y);

    y += (0.5 * cm2px);
    documento.setFontSize(6);
    documento.setFont(fontName, 'BoldOblique');
    documento.text('Esta Administración Federal no se responsabiliza por los datos ingresados en el detalle de la operación', x, y);

    //CAE
    let ww = 0;
    x = (centro + (5 * cm2px));
    y = h;
    documento.setFontSize(9);
    documento.setFont(fontName, 'Bold');
    documento.text('CAE Nº:', x, y);
    ww = documento.getTextWidth('CAE Nº: ');
    documento.setFont(fontName, 'normal');
    documento.text(data.fv0.fv0_numcai, (x + ww + 2), y);

    //vto CAE
    x = (centro + (2.8 * cm2px));
    y += (0.5 * cm2px);
    documento.setFontSize(9);
    documento.setFont(fontName, 'Bold');
    documento.text('Fecha de Vto. de CAE:', x, y);
    ww = documento.getTextWidth('Fecha de Vto. de CAE: ');
    documento.setFont(fontName, 'normal');
    documento.text(moment(data.fv0.fv0_feccai).format('DD/MM/YYYY'), (x + ww + 2), y);
    
    x = ((wp-rm)-(3*cm2px));
    y = (hp-bm-20);
    documento.text('Pag.: '+pag_act+' de '+pag_total, x, y);
}


export default reporteFactura